.btn_logout {
  z-index: 10000;
  position: absolute;
  bottom: 0;
  right: 0;
}

.card {
  width: 100% !important;
  max-width: 100% !important;
}

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.card {
  width: 100% !important;
}

video {
  position: absolute;
  right: 100%;
}
/*# sourceMappingURL=index.c4a3c2d6.css.map */
