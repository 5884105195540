.btn_logout {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10000;
}

.card {
    max-width: 100% !important;
    width: 100% !important;
}

body {
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.card {
    width: 100% !important;
}

video {
    position: absolute;
    right: 100%;
}
